.withDraw-form {
  .box-card-bottom {
    padding: 48px 32px 32px;

    .totalAmount {
      @include rtl-sass-value(text-align, right, left);
    }

    .title,
    .totalAmount {
      margin-bottom: 30px;
      font-weight: 800;
      font-size: 20px;
      line-height: 27px;
      color: #000;
    }

    .availableTypes {
      margin-top: 30px;
    }
    /deep/.form-bottom-row {
      position: relative;
      .img-row {
        text-align: center;
        float: none;

        @media (min-width: 1200px) {
          position: absolute;
          top: 0;
          // right: 0;
          @include rtl-sass-prop(right, left, 0);
        }

        img {
          display: block;
          height: 80px;

          &.long-strip-icon {
            height: 40px;
          }

          &.small {
            max-width: 100px;
            height: auto;
          }
        }
      }

      .form_container {
        display: flex;
        flex-wrap: wrap;
      }
      .form_row {
        width: 100%;
        // max-width: 66.6%;
        max-width: 100%;

        .labelTitle {
          margin-bottom: 10px;
        }
      }
      .form_upload {
        width: 30%;
        margin-top: 24px;
        @include rtl-sass-prop(margin-left, margin-right, 3.3%);

        .labelTitle {
          margin-bottom: 16px;
        }
        .el-form-item__content {
          line-height: 1.5;
        }
      }

      .form-list {
        display: grid;
        grid-template-columns: 48% 48%;
        column-gap: 4%;
      }
      @media (min-width: 1200px) {
        .form-list {
          max-width: calc(100% - 280px);
        }
        .form-list.two-fields {
          max-width: 100%;
          display: flex;
          flex-direction: column;
          li {
            width: 100%;
            &:nth-child(1) {
              width: 48%;
            }
            & + li {
              margin-top: 28px;
            }
          }
        }
        .form-list.single-field {
          display: flex;
          li {
            width: 100%;
          }
        }
      }

      .alert-info {
        display: flex;
        align-items: center;
        font-size: 14 px;
        padding-top: 10px;
        padding-bottom: 36px;
        color: $future-blue;
        p {
          line-height: 20px;
        }
        &_icon {
          width: 20px;
          height: 20px;
          margin-right: 12px;
        }
      }

      .checkbox-wrapper {
        margin: 44px 0 30px;
        width: 100%;
      }

      .transfer-info-container {
        background: #f3f4f8;
        border-radius: 6px;
        height: 88px;
        text-align: start;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px;
        width: 100%;
        margin-top: 24px;
      }
    }

    /deep/ {
      #hongkongForm {
        .rate {
          position: relative;
          top: -40px;
        }
      }
      #IndiaForm {
        .rate {
          position: relative;
          top: -40px;
        }
      }
      #netellerForm {
        .el-form-item {
          margin-bottom: 15px;
        }
      }

      #fasapayForm {
        .el-form-item {
          margin-bottom: 15px;
        }
        .form-list:first-child {
          margin-bottom: 25px;
        }
      }

      #skrillForm {
        .el-form-item {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.head-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 32px 0;
}

.head-title {
  font-size: 14px;
  font-weight: 700;
  color: $text-primary;
}

.total-amount {
  text-align: right;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .withDraw-form {
    .box-card-bottom /deep/.form-bottom-row {
      .img-row,
      .form_row {
        float: none;
      }

      .img-row {
        margin-bottom: 50px;

        img {
          margin: 0 auto;
        }
      }

      .form_row {
        max-width: 100%;
      }

      .form-list {
        grid-template-columns: 47.5% 47.5%;
        column-gap: 5%;
      }

      .form_upload {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 800px) {
  .head-title {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .head-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .head-title {
    text-align: center;
  }

  .total-amount {
    text-align: center;
  }
}

@media (max-width: 550px) {
  .box-card-bottom /deep/ {
    padding: 30px 24px;
    .form-bottom-row .form-list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));

      .int_p {
        margin-bottom: 24px;
      }
    }
  }
}
