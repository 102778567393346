
              @import "@/assets/css/variables.scss";
            










































































































































.verification-code-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .verification-code-item:nth-child(1) {
    flex: 1;
  }

  .verification-code-item + .verification-code-item {
    @include rtl-sass-prop(margin-left, margin-right, 8px);
  }
}

/deep/ .btn-blue {
  margin-top: 0 !important;
}

.warn_text {
  font-size: 12px;
  color: $danger;
}

.primary_text {
  font-size: 12px;
  color: $text-secondary;
  margin-top: 8px;
}
