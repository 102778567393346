
              @import "@/assets/css/variables.scss";
            







































































































.el-form-item {
  input {
    position: sticky;
    z-index: 101;
  }

  &.finish /deep/ label {
    font-size: 14px;
    bottom: 45px;
  }
}

.logo {
  height: 48px !important;
}

@media (min-width: 1200px) {
  .logo {
    height: 36px !important;
  }
}
