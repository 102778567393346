.common-dialog-wrapper {
  .dialog /deep/ {
    // &.el-dialog__wrapper .el-dialog.el-dialog--center {
    //   border-radius: 20px;
    //   max-height: 90%;
    //   height: auto;
    //   box-shadow: none;
    // }

    // .el-dialog__header {
    //   position: sticky;
    //   top: 0;
    //   min-height: 42px;
    //   background-color: inherit;
    //   z-index: 2;
    //   border-top-left-radius: 20px;
    //   border-top-right-radius: 20px;
    // }

    .el-dialog__body {
      // padding: 36px 68px 60px 68px;
      li {
        text-align: left;
      }
      .el-checkbox {
        .el-checkbox__input.is-checked .el-checkbox__inner::after {
          border-color: $black;
        }

        .el-checkbox__inner {
          transform: scale(2);
          border-color: #d8d8d8;
          border-radius: 4px;
          margin-left: 8px;
        }

        .el-checkbox__input.is-checked,
        .el-checkbox__input.is-focus {
          .el-checkbox__inner {
            border-color: #d8d8d8;
          }
        }
      }
    }

    .el-dialog__footer {
      // padding: 0;

      .chat {
        font-size: 15px;
        color: $dark-gray;
        text-transform: uppercase;
        line-height: 1.2;

        img,
        div {
          display: inline-block;
        }

        img {
          @include rtl-sass-prop(margin-right, margin-left, 10px);
          margin-bottom: 5px;
        }
      }
    }
  }
}

// .dialog-body {
//   @include rtl-sass-value(padding, 0 35px 45px 30px, 0 30px 45px 35px);
// }

.el-dialog__footer {
  .chat {
    @include rtl-sass-value(text-align, left, right);
    @include rtl-sass-value(padding, 0 55px 45px 50px, 0 50px 45px 55px);
  }

  img {
    @include rtl-sass-prop(margin-right, margin-left, 10px);
  }
}

// @media (max-width: 767px) {
//   .common-dialog-wrapper {
//     .dialog /deep/ {
//       &.el-dialog__wrapper .el-dialog.el-dialog--center {
//         top: 3%;
//         border-radius: 8px;
//       }

//       .el-dialog__body {
//         padding: 30px 20px;
//       }

//       .el-dialog__footer .chat {
//         padding: 0 20px 20px;
//       }
//     }
//   }

//   .dialog-body {
//     @include rtl-sass-value(padding, 0 35px 45px 30px, 0 30px 45px 35px);
//   }
// }
