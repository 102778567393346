
              @import "@/assets/css/variables.scss";
            


















































































































@import '@/assets/css/components/vUpload.scss';
.upload {
  max-width: 500px;
  .el-form-item /deep/ {
    margin-bottom: 0 !important;
  }
}
.upload-demo {
  background-color: #f8f9fc;
  height: 129px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #dfe4eb;

  .upload_icon {
    font-size: 32px;
  }

  .button-upload {
    margin-left: 0;
    margin-top: 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $text-secondary;
  }
}

/deep/ .el-upload {
  border: none;
}

.el-upload__tip {
  color: $primary;
  margin-top: 20px;

  ul {
    margin-left: 16px;
  }
  li {
    list-style: disc;
  }
}
