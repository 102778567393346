.l-select {
  position: relative;
  max-width: 500px;
}

.select_withdraw {
  width: 100%;
  /deep/ {
    .is-disabled .el-input__inner,
    .el-input__inner {
      border: 1px solid $border-color-base;
      border-radius: 4px;
    }

    .is-focus .el-input__inner,
    .el-input__inner {
      height: 60px;
      font-size: 0;
      background-position: right 10px center;
    }
    .el-input__prefix {
      .type-Img,
      .type-Img50 {
        float: left;
        @include rtl-sass-value(margin-left, 15px, 30px);
        margin-top: -5px;
      }
    }
    .is-disabled .el-input__prefix {
      color: #cccfd4;
    }
  }
}

// option
.el-select-dropdown__item {
  height: 60px;
}
.select-options-container {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .option_text {
    color: $text-primary;
    font-weight: 700;
  }

  .option_image {
    height: 40px;
  }
}
// option end
.select_wrapper {
  display: flex;
  height: 60px;
  align-items: center;
  @include rtl-sass-prop(padding-left, padding-right, 10px);

  .select_img {
    height: 32px;
  }

  .select_content {
    @include rtl-sass-prop(margin-left, margin-right, 8px);
    .title {
      color: $text-primary;
      font-size: 14px;
      line-height: 22px;
    }
    .amount {
      color: $text-secondary;
      font-size: 12px;
      line-height: 18px;
      text-align: left;
    }
  }
}
.select_text {
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  color: $primary;
  @include rtl-sass-prop(padding-left, padding-right, 10px);
}
.credit_box {
  border: 1px solid $border-color-base;
  border-radius: 4px;
  height: 60px;
  padding: 8px 16px;
  background-color: #fafafa;

  .title {
    line-height: 22px;
    color: $text-primary;
  }

  .amount {
    line-height: 18px;
    color: $text-secondary;
    margin-top: 2px;
  }
}

@media (max-width: 1440px) {
  .select_withdraw {
    /deep/ {
      .select-text {
        max-width: 192px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .select_withdraw {
    /deep/ {
      .select-text {
        max-width: none;
      }
    }
  }
}

@media (max-width: 768px) {
  .select_withdraw {
    /deep/ {
      .select-text {
        max-width: 200px;
      }
    }
  }
}

@media (max-width: 550px) {
  .select_withdraw {
    /deep/ {
      .select-text {
        &.small-text {
          font-size: 12px;
          max-width: 100px;
        }
      }
    }
  }
}

@media (max-width: 380px) {
  .select_withdraw {
    /deep/ {
      .el-input__prefix {
        .type-Img,
        .type-Img50 {
          display: none;
        }
      }
    }
  }
}
