.credit-card-content-wrapper {
  margin-top: 28px;
  padding: 4px;
}
.credit-card-content {
  flex-wrap: wrap;
}

.card-sample {
  text-align: center;
  margin-top: 8px;
}

.expire-date {
  display: flex;
}
.month-select {
  width: 35%;
  margin-right: 16px;
}
.year-select {
  width: 55%;
}

.archive-action {
  margin-top: 30px;
}

.archive-upload {
  margin-top: 48px;

  /deep/ .el-upload__tip {
    margin: 0px;
  }
}

@media (min-width: 992px) {
  .archive-form {
    display: flex;

    &-upload {
      flex: 1 1 auto;

      & + & {
        margin-left: 24px;
      }
    }
  }
}
