
              @import "@/assets/css/variables.scss";
            


















































































































































































































































































































































.submit_box {
  max-width: 500px;
  margin-top: 20px;

  .checkbox {
    margin-top: 10px;
  }
  .submit_button {
    margin-top: 20px;
  }
}
// @import '@/assets/css/pages/withdrawFunds.scss';
// @import '@/assets/css/components/common/Input';
// .mb-2 {
//   margin-bottom: 8px;
// }

// /deep/ .verification-code-container {
//   justify-content: start !important;
//   .verification-code-item + .verification-code-item {
//     margin-left: 36px;
//   }
//   .verification-code-item {
//     .el-button {
//       height: 39px;
//       padding: 8px 16px;
//     }
//   }
// }
