
              @import "@/assets/css/variables.scss";
            













































































































































































































































































































































@media (min-width: 1200px) {
  .form_row.bank-transfer {
    max-width: 66.6% !important;

    .form-list {
      max-width: 100% !important;
    }
  }
}
