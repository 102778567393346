.withDraw-form {
  margin-top: 10px;
  .box-card-mid {
    padding: 48px 32px 32px;

    .totalAmount {
      @include rtl-sass-value(text-align, right, left);
      margin: 15px 0;
    }

    .expired-cc {
      margin-top: 48px;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0;

        .hide-cc {
          cursor: pointer;
        }
      }
    }
  }
}

.head-wrapper {
  display: flex;
  justify-content: space-between;
}

.head-title {
  font-size: 14px;
  font-weight: 700;
  color: $text-primary;
}

.total-amount {
  font-size: 14px;
  text-align: right;
  font-weight: 700;
  color: $text-primary;
}

.hide-cc {
  line-height: 47px;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .withDraw-form {
    .box-card-mid {
      .tabs {
        .tab {
          /deep/ {
            .img-row {
              .card_sample {
                margin: 10px 0 0 10px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .withDraw-form {
    .box-card-mid {
      .tabs {
        .tab {
          &-title {
            .el-col {
              justify-content: left;
            }
          }
          .arrow,
          .card_status {
            margin-top: 10px;
          }
        }
      }
    }
  }

  .head-title {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .head-wrapper {
    display: flex;
    flex-direction: column;
  }

  .head-title {
    text-align: center;
  }

  .total-amount {
    text-align: center;
  }

  .hide-cc {
    text-align: center;
  }
}
