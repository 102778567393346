
              @import "@/assets/css/variables.scss";
            






















































































.logo {
  height: 48px !important;
}

@media (min-width: 1200px) {
  .logo {
    height: 36px !important;
  }
}
