.credit-card-group {
  margin-top: 10px;
}
.credit-card-tab {
  padding: 20px 24px;
  background: $white;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.credit-card-container {
  margin-top: 28px;
}

.item-check {
  display: none;
}

.credit-card-tab {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.credit-card-icon {
  height: 40px;
  width: 76px;
  img {
    height: 100%;
  }

  &.visa {
    background-image: url(~@/assets/images/withdraw/visa_icon.png);
    background-position: left;
    background-size: auto 24px;
    background-repeat: no-repeat;
  }

  &.master-card {
    background-image: url(~@/assets/images/withdraw/master_card_icon.png);
    background-position: left;
    background-size: auto 40px;
    background-repeat: no-repeat;
  }
}

.tab-text {
  line-height: 40px;
  white-space: nowrap;
  // margin-right: 4%;
  display: flex;
  align-items: center;
  font-size: 16px;

  & + .tab-text {
    margin-left: 8%;
  }
}

.amount-number {
  font-weight: 900;
  font-size: 16px;
  line-height: 40px;
}

.select-icon {
  font-size: 16px;
  cursor: pointer;
  padding: 10px;
  transition: transform 0.3s ease-in-out;

  &.open {
    transform: rotate(180deg);
  }
}

.title_form {
  flex: 1;
  .el-form-item--small.el-form-item {
    margin-bottom: 0;
  }
}
