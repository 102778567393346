
              @import "@/assets/css/variables.scss";
            



















































































































@import '@/assets/css/components/common/SelectLine';
@media (min-width: 1200px) {
  .top-bank-transfer {
    .form-list {
      max-width: 66.6% !important;
    }
  }
}
