
              @import "@/assets/css/variables.scss";
            









































































































































































.title {
  font-size: 16px;
  font-weight: 700;
  color: $text-primary;
}

.withdraw-note {
  font-size: 13px;
  color: $text-secondary;
}
