/deep/ .el-dialog__body {
  padding: 45px 55px 35px;
  text-align: center;
  word-break: normal;

  p {
    margin-top: 30px;
    margin-bottom: 50px;
    font-size: 15px;
    color: rgb(98, 109, 113);
    line-height: 2;
  }

  img {
    width: 50px;
  }

  a {
    margin-top: 30px;
    font-size: 16px;
    margin-bottom: 50px;
    color: #90bbea;
  }

  .btn-default {
    width: 48%;
    @include rtl-sass-prop(margin-right, margin-left, 4%);
  }

  .btn-blue {
    width: 48%;
  }
}

/deep/ .el-dialog__header {
  // hide default close icon
  .el-dialog__headerbtn {
    display: none;
  }
}

.info-icon {
  width: 15%;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

@media (max-width: 550px) {
  /deep/ .el-dialog__body {
    padding: 35px;

    .btn-red,
    .btn-default {
      max-width: 45% !important;
    }
  }
}

@media (max-width: 500px) {
  /deep/ .el-dialog__body {
    padding: 20px;
  }
}
