
              @import "@/assets/css/variables.scss";
            














































































































































































































































































































// @import '@/assets/css/components/withdraw/topLeft.scss';
.account_wrapper {
  max-width: 500px;
}
