.promotion /deep/ .el-dialog__body {
  padding: 30px 30px 30px !important;

  .top {
    .header-text {
      font-size: 18px;
      color: $bottle-blue;
      margin-bottom: 35px;
    }
    .title {
      font-size: 15px;
      color: $red;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: 20px;
    }
    .title--blue {
      font-size: 15px;
      color: $blue;
      text-transform: uppercase;
      line-height: 1.2;
      margin-bottom: 20px;
      @include rtl-sass-prop(padding-left, padding-right, 4px);
    }
    .title--grey {
      font-size: 15px;
      color: rgb(113, 123, 117);
      margin-bottom: 6px;
      @include rtl-sass-prop(padding-left, padding-right, 4px);
    }
    li {
      word-break: break-word;
      line-height: 1.6;
      font-size: 13px;
      padding-bottom: 15px;
      text-align: justify;
    }
  }

  .el-form {
    .el-form-item {
      .el-form-item__label {
        font-size: 15px;
        color: rgb(113, 123, 117);
        margin-bottom: 20px;
        &::before {
          content: '';
        }
      }
    }
    .select--RAF {
      .el-input__inner {
        border-color: #efeff5;
        border-radius: 6px;
        background-color: $light-gray;
      }
    }
  }

  /deep/.el-form {
    .el-select .el-input__inner {
      border: none;
      border-style: unset;
      background-color: $light-gray;
    }
  }

  .tnc {
    margin-top: 20px;
    .tncLabel {
      display: inline !important;
      color: $red;
    }
    .checkbox {
      display: inline !important;
    }
  }

  .form-box {
    margin-top: 40px;

    /deep/ .el-form-item__label {
      font-size: 15px;
      color: $dark-gray;
      text-transform: uppercase;
    }

    /deep/ .el-form .el-input__inner {
      background-color: $light-gray;
    }

    .forgotPassword {
      @include rtl-sass-value(text-align, right, left);

      .forgotPwd {
        font-size: 15px;
        color: $dark-gray;
        text-decoration: underline;
        line-height: 1.2;
      }
    }

    .btn-wrapper {
      display: flex;
      justify-content: center;
      .btn-red {
        width: 200px;
        &.is-disabled {
          background-color: #c0c4cc;
          color: #fff;
        }
        &.is-disabled:hover {
          background-color: #c0c4cc;
        }
      }
    }

    .btn-box {
      display: grid;
      grid-template-columns: 45% 45%;
      column-gap: 10%;
      margin-top: 30px;
      .long-text-btn {
        white-space: initial;
      }
    }

    .info-wrapper {
      border: 1px dashed #2a1e39;
      padding: 25px 45px;
      border-radius: 8px;
      margin-bottom: 40px;
      .info1 {
        font-size: 14.257px;
        color: rgb(113, 123, 117);
        line-height: 1.2;
        word-break: break-word;
      }
      .info2 {
        border-top: 1px dashed #2a1e39;
        font-size: 14.257px;
        color: $red;
        line-height: 1.2;
        padding-top: 20px;
        margin-top: 20px;
        word-break: break-word;
      }
    }

    .agreeTnc-wrapper {
      font-size: 13px;
      font-weight: 200;
      color: rgb(113, 123, 117);
      margin-top: 26px;
      a {
        font-size: 14px;
        font-weight: 200;
        color: rgb(113, 123, 117);
        border-bottom: 1px solid rgb(113, 123, 117);
      }
    }
  }
}

@media (max-width: 767px) {
  .promotion /deep/ .el-dialog__body {
    padding: 20px !important;

    .top {
      grid-template-columns: 48% 48%;
      column-gap: 4%;
      margin-bottom: 10px;
    }

    .form-box {
      .btn-box {
        grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
        row-gap: 20px;
        margin-top: 40px;
      }
    }
  }
}
