.int_p {
  border-color: rgb(98, 109, 113);
  border-style: solid;
  border-width: $border-width;
  border-color: $border-color;
  border-radius: 6px;
  background-color: $white;
  padding: 10px;
  font-size: 15px;
  color: rgb(98, 109, 113);
  line-height: 1.333;
}

.checkbox-wrapper /deep/ .el-checkbox__label {
  font-size: 15px;
  color: $bottle-blue !important;
  line-height: 1.799;
  margin-bottom: 20px;
}

.digitsCard {
  .col_4 {
    width: 32%;
    display: inline-block;

    span {
      font-size: 18px;
      color: #8fafd9;
    }

    &.middle {
      margin: 0 2%;
      padding-top: 10px;
      text-align: center;
    }
  }
}

.digitsCard__subinfo {
  margin-bottom: 10px;
}

.expriredate__info {
  .month,
  .year {
    width: 48%;
    display: inline-block;
  }

  .year {
    @include rtl-sass-value(float, right, left);
  }
}

.btn-box {
  text-align: center;

  .btn-red {
    width: 100%;
  }
}

.alert-info {
  font-size: 12px;
  padding-bottom: 30px;
  color: red;
}

#netellerForm {
  .el-form-item {
    margin-bottom: 15px;
  }
}

#skrillForm {
  .el-form-item {
    margin-bottom: 15px;
  }
}

#fasapayForm {
  .el-form-item {
    margin-bottom: 15px;
  }
}
